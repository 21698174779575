import { useMemo, useId } from 'react';
import LoginForm from '@/components/login/form';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ModalLayout, { ModalLayoutProps } from '@/layouts/modal/modal.layout';
import cancelIcon from '@/public/assets/icons/cross-white.svg';
import './login-modal.scss';

export interface LoginModalProps extends ModalLayoutProps {
    onSuccess?: () => void;
    onError?: () => void;
}

export default function LoginModal({ toggle, handleToggle, onSuccess, onError }: LoginModalProps) {
    const id = useId();
    const loginForm = useMemo(
        () =>
            toggle ? (
                <GoogleReCaptchaProvider
                    container={{
                        element: id,
                        parameters: {
                            badge: 'bottomright',
                        },
                    }}
                    reCaptchaKey={process.env.NEXT_PUBLIC_SITE_KEY || ''}
                >
                    <LoginForm asModal onSuccess={onSuccess} onError={onError} />
                </GoogleReCaptchaProvider>
            ) : null,
        [toggle, id, onSuccess, onError]
    );

    return (
        <ModalLayout
            toggle={toggle}
            handleToggle={handleToggle}
            backDropClassName="login-modal"
            dialogClassName="login-modal__container"
            cancelBtnClassName="login-modal__container__cancel"
            cancelIcon={cancelIcon}
            static
        >
            {loginForm}
            <div className="login-modal__recaptcha" id={id}></div>
        </ModalLayout>
    );
}
