import React, { useState } from 'react';
import Input from '@/components/inputs/input';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useLoginMutation } from '@/redux/auth/authApi';
import { accessRoutes, appRoutes } from '@/utils/routes/routeConfiguration';
import { isEmpty } from 'lodash';
import { MSG_DISPLAY } from '@/utils/msgDisplay';
import { ErrorToastContainer } from '@/components/toasts';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { pageRoutes } from '@/utils/routes/urls';
import classNames from 'classnames';
import HTTP_STATUS from '@/utils/httpStatus';
import { Role } from '@/redux/user/slice';
import './login-form.scss';

interface LoginFormProps {
    asModal?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
}

export default function LoginForm({ asModal, onSuccess, onError }: LoginFormProps) {
    const router = useRouter();
    const [login, { isLoading }] = useLoginMutation();
    const [formErrors, setFormErrors] = useState<any>({});
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [captchaError, setCaptchaError] = useState('');

    const [inputData, setInputData] = useState({
        email: '',
        password: '',
    });

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const fieldName = e.currentTarget.name;
        const fieldValue = e.currentTarget.value;
        setInputData((prev) => ({
            ...prev,
            [fieldName]: fieldValue,
        }));
        if (!isEmpty(formErrors[fieldName])) {
            setFormErrors((prev: any) => ({
                ...prev,
                [fieldName]: '',
            }));
        }
    }

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        if (executeRecaptcha) {
            executeRecaptcha()
                .then(() => {
                    login(inputData)
                        .unwrap()
                        .then(async (authState) => {
                            if (onSuccess) onSuccess();
                            else
                                await router.replace(
                                    accessRoutes[authState.loggedInUser.roles?.[0].title || Role.GUEST]
                                );
                        })
                        .catch((error) => {
                            if (onError) onError();
                            if (error?.status === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
                                const serverSideErrors: any = error.data?.errors;
                                Object.keys(serverSideErrors).forEach((key) => {
                                    setFormErrors((prev: any) => ({
                                        ...prev,
                                        [key]: MSG_DISPLAY.requiredErr,
                                    }));
                                });
                            } else {
                                return ErrorToastContainer({
                                    message: error?.data?.message || MSG_DISPLAY.sthWentWrong,
                                });
                            }
                        });
                })
                .catch((err) => setCaptchaError(MSG_DISPLAY.requiredErr));
        } else {
            setCaptchaError(MSG_DISPLAY.requiredErr);
        }
    }

    return (
        <section className={classNames('login-component', asModal && 'login-component--modal')}>
            <h1 className="login-component__heading">Login</h1>
            <form onSubmit={handleSubmit} className="login-component__form" noValidate>
                <div className="login-component__form__field">
                    <label className="login-component__form__field__label">メールアドレス</label>
                    <Input
                        name="email"
                        onInput={handleInputChange}
                        type="email"
                        value={inputData.email}
                        formError={formErrors.email}
                    />
                </div>
                <div className="login-component__form__field">
                    <label className="login-component__form__field__label">パスワード</label>
                    <Input
                        name="password"
                        onInput={handleInputChange}
                        type="password"
                        value={inputData.password}
                        formError={formErrors.password}
                    />
                </div>
                <div className="login-component__form__submit">
                    <button type="submit" className="login-component__form__submit__btn" disabled={isLoading}>
                        {isLoading ? '' : 'ログイン'}
                    </button>
                    <Link href={pageRoutes.forgetPassword} className="login-component__form__forget-password">
                        パスワード再発行はこちら
                    </Link>
                </div>
            </form>
            <div
                className={classNames(
                    'login-component__unregistered',
                    asModal && 'login-component__unregistered--modal'
                )}
            >
                <div>会員登録がお済みでない場合</div>
                <div className="login-component__unregistered__nav">
                    <Link
                        role="button"
                        href={appRoutes.memberRegistration}
                        className="login-component__unregistered__nav__item__btn"
                    >
                        会員登録
                    </Link>
                </div>
            </div>
        </section>
    );
}
