import VideoPlayer from '@/components/video-player';
import { ModalLayoutProps } from '@/layouts/modal/modal.layout';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { playBackRates } from '@/utils/constants';
import DropdownField from '@/components/inputs/dropdownField';
import cancelIcon from '@/public/assets/icons/cross-white.svg';
import classNames from 'classnames';
import Image from 'next/image';
import './video-modal.scss';

export interface VideoModalProps extends Pick<ModalLayoutProps, 'toggle' | 'handleToggle'> {
    url: string;
    poster?: string;
    playbackSpeed?: number;
}

function generateVideoSettings({ url, poster }: Pick<VideoModalProps, 'poster' | 'url'>): VideoJsPlayerOptions {
    return {
        controls: true,
        responsive: true,
        playbackRates: playBackRates,
        autoplay: true,
        controlBar: {
            pictureInPictureToggle: false,
            currentTimeDisplay: true,
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            playbackRateMenuButton: false,
        },
        poster: poster,
        sources: [
            {
                src: url,
                type: 'application/x-mpegURL',
            },
        ],
    };
}

export default function VideoModal(props: VideoModalProps) {
    const playerRef = useRef<VideoJsPlayer | null>(null);
    const [selectedPlayBackRate, setSelectedPlayBackRate] = useState<number>(props?.playbackSpeed || 1);

    const handlePlayerReady = useCallback(
        (player: VideoJsPlayer) => {
            playerRef.current = player;

            player.on('waiting', () => {
                videojs.log('player is waiting');
            });

            player.ready(function () {
                this.playbackRate(props?.playbackSpeed || 1); //set default playRate
            });

            //   const isPause = React.useRef<number>(0);
            // player.on('timeupdate', function (e) {
            //     if (isPause.current == 0 && (player.currentTime() >= 10)) {
            //         player.pause();
            //         isPause.current = 1;
            //     }
            // }); //Pause after 10s of play and then play normally on resume

            player.on('dispose', () => {
                videojs.log('player will dispose');
            });
        },
        [props?.playbackSpeed]
    );

    function handlePlayBackRateSelect(value: string | number) {
        const rate = playBackRates.find((elem) => value === elem);
        rate && setSelectedPlayBackRate(rate);
    }

    const videoPlayer = useMemo(
        () => (
            <VideoPlayer
                options={generateVideoSettings({ url: props.url, poster: props.poster })}
                videoPlayerClassName="player-dialog__video-player"
                onReady={handlePlayerReady}
            />
        ),
        [handlePlayerReady, props.poster, props.url]
    );

    useEffect(() => {
        if (playerRef.current) {
            const player = playerRef.current;

            const lastCurrentTime: number = player.ended() ? 0 : player.currentTime();
            const isPlaying: boolean = !player.paused();

            if (selectedPlayBackRate !== player.playbackRate()) {
                player.ready(function () {
                    this.playbackRate(selectedPlayBackRate);
                });

                // Adjust the playback position when speed changes
                player.on('ratechange', () => {
                    player.currentTime(lastCurrentTime);
                    if (isPlaying) {
                        player.play()?.catch((err: Error) => {});
                    } else player.pause();
                });
            }
        }
    }, [playerRef, selectedPlayBackRate]);

    return (
        <div className={classNames('player-dialog', props.toggle && 'player-dialog--open')}>
            <div className="player-dialog__content">
                <button
                    onClick={() => props.handleToggle(false)}
                    className="player-dialog__btn player-dialog__btn--close"
                >
                    <Image src={cancelIcon} alt="cross" fill />
                </button>
                {props.toggle && (
                    <div className="player-dialog__video">
                        {videoPlayer}
                        <div className="player-dialog__playback">
                            <DropdownField
                                name=""
                                value={selectedPlayBackRate}
                                setInputData={(_, value) => handlePlayBackRateSelect(value)}
                                options={playBackRates.map((elem) => ({ label: `${elem}倍速`, value: elem }))}
                                compClassNames={{
                                    inputfield: 'player-dialog__playback-wrapper',
                                    menu: 'player-dialog__playback-menu',
                                    icon: 'player-dialog__playback-icon',
                                }}
                                itemComponent={({ label, selected, onChange }) => (
                                    <div
                                        className={classNames(
                                            'player-dialog__playback-item',
                                            selected && 'player-dialog__playback-item--selected'
                                        )}
                                        onClick={onChange}
                                    >
                                        {label}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
