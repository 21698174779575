import Image, { StaticImageData } from 'next/image';
import React, { PropsWithChildren } from 'react';
import './modal.layout.scss';
import classNames from 'classnames';

export interface ModalLayoutProps {
    toggle: boolean;
    handleToggle: React.Dispatch<React.SetStateAction<boolean>> | ((value: boolean) => void);
    static?: boolean;
    onClose?: () => void;
    dialogClassName?: string;
    backDropClassName?: string;
    cancelIcon?: string | StaticImageData;
    cancelBtnClassName?: string;
}

export default function ModalLayout(props: PropsWithChildren<ModalLayoutProps>) {
    const modalRef = React.useRef<HTMLDivElement | null>(null);

    function handleBackgroundClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (!props.static && event.target === modalRef.current) {
            props.handleToggle(false);
        }
    }

    function onBtnClose() {
        props.onClose && props.onClose();
        props.handleToggle(false);
    }

    return (
        <div
            className={classNames('modal', props.backDropClassName, props.toggle && 'modal--show')}
            ref={modalRef}
            onClick={handleBackgroundClick}
        >
            <div className={classNames('modal__dialog', props.dialogClassName)}>
                <button className={classNames('modal__close', props.cancelBtnClassName)} onClick={onBtnClose}>
                    <Image src={props.cancelIcon || '/assets/images/modal/cross.svg'} alt="close" fill />
                </button>
                {props.children}
            </div>
        </div>
    );
}
