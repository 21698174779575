import Image from 'next/image';
import { HTMLInputTypeAttribute } from 'react';
import './Input.scss';
import ErrorMessage from '@/components/common/errorMessage';
import { validatePictogram } from '@/utils/pictogramValidator';
import classNames from 'classnames';

export interface InputProps {
    label?: string;
    required?: boolean;
    type: HTMLInputTypeAttribute;
    name: string;
    value?: string;
    placeholder?: string;
    onInput: React.FormEventHandler<HTMLInputElement>;
    formError?: string;
}

export default function Input(props: InputProps) {
    function handleChange(e: React.FormEvent<HTMLInputElement>) {
        if (validatePictogram(e.currentTarget.value)) {
            props.onInput(e);
        }
    }
    return (
        <div className="custom-input">
            {props.label && (
                <label className="custom-input__label">
                    {props.label} {props.required && <span className="custom-input__label--required">必須</span>}
                </label>
            )}
            <input
                name={props.name}
                type={props.type}
                placeholder={props.placeholder}
                required={props.required}
                className={classNames(`custom-input__input`, props.formError && 'custom-input__input--error')}
                value={props.value}
                onInput={handleChange}
            />
            {props.formError && <ErrorMessage message={props.formError} />}
        </div>
    );
}
