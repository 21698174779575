import Image from 'next/image';
import React, { useEffect, useId, useRef, useState } from 'react';
import checkIcon from '@/public/assets/icons/Check green.svg';
import ErrorMessage from '@/components/common/errorMessage';
import classNames from 'classnames';
import './dropdownField.scss';

export interface SelectItem {
    value: number | string;
    label: string;
}

export interface DropdownFieldProps<T extends SelectItem> {
    label?: string;
    required?: boolean;
    name: string;
    options: T[];
    value?: string | number;
    setInputData: (name: string, value: string | number) => void;
    placeholder?: string;
    formError?: string;
    formErrors?: any;
    disabled?: boolean;
    noErrorMessage?: boolean;
    validationFunction?: (name: string) => void;
    compClassNames?: {
        inputfield?: string;
        icon?: string;
        menu?: string;
    };
    itemComponent?: ({
        selected,
        onChange,
        label,
    }: Pick<SelectItem, 'label'> & {
        selected: boolean;
        onChange: () => void;
    }) => React.ReactElement;
}

function DropdownField<T extends SelectItem>({
    label,
    required,
    name,
    options,
    value,
    setInputData,
    placeholder,
    formError,
    formErrors,
    disabled,
    noErrorMessage,
    validationFunction,
    itemComponent,
    compClassNames = {},
}: DropdownFieldProps<T>) {
    const [displayOptions, setDisplayOptions] = useState<boolean>(false);
    const dropDownRef = useRef<HTMLDivElement | null>(null);
    const dropdownId = useId();

    function handleChange(item: string | number) {
        if (formError && formErrors) {
            delete formErrors[name];
        }
        setInputData(name, item);
        setDisplayOptions((prev) => !prev);
        if (formError && validationFunction) validationFunction(name);
    }

    function handleDrop() {
        setDisplayOptions((prev) => !prev);
    }

    function getInputLabel() {
        const item = options.find((elem) => elem.value === value);
        return item?.label || value || '';
    }

    useEffect(() => {
        const closeOptions = (e: any) => {
            if (e.target && !dropDownRef.current?.contains(e.target)) {
                setDisplayOptions(false);
            }
        };
        document.body.addEventListener('mousedown', closeOptions);
        return () => document.body.removeEventListener('mousedown', closeOptions);
    }, []);

    return (
        <div className="dropdown-container" data-disabled={disabled}>
            {label && (
                <label className="dropdown-container__label">
                    {label} {required && <span className="dropdown-container__label--required">(必須)</span>}
                </label>
            )}
            <div className="dropdown-container__field-wrapper" ref={dropDownRef} id={dropdownId}>
                <div
                    className={classNames(
                        'dropdown-container__field',
                        displayOptions && 'dropdown-container__field--active',
                        compClassNames.inputfield,
                        formError && 'dropdown-container__field--error',
                        disabled && 'dropdown-container__field--disabled'
                    )}
                    onClick={handleDrop}
                >
                    <input
                        type="text"
                        name={name}
                        placeholder={placeholder || '---'}
                        value={getInputLabel()}
                        readOnly
                        className="dropdown-container__value"
                    />
                    <span
                        className={classNames(
                            `dropdown-container__icon `,
                            compClassNames.icon,
                            displayOptions && 'dropdown-container__icon--spin'
                        )}
                    ></span>
                </div>
                {!disabled && displayOptions && (
                    <div className={classNames('dropdown-container__option-div', compClassNames.menu)}>
                        {options.map((op, i) => (
                            <React.Fragment key={`op-${i}`}>
                                {itemComponent?.({
                                    label: op.label,
                                    selected: value === op.value,
                                    onChange: () => handleChange(op.value),
                                }) ?? (
                                    <div
                                        className="dropdown-container__option-item"
                                        onClick={() => handleChange(op.value)}
                                    >
                                        <p
                                            className={classNames(
                                                `dropdown-container__text`,
                                                value === op.value && 'dropdown-container__text--active'
                                            )}
                                        >
                                            {op.label}
                                        </p>
                                        {value === op.value && (
                                            <div className="dropdown-container__check">
                                                <Image src={checkIcon} alt="1" />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
            {formError && !noErrorMessage && <ErrorMessage message={formError} />}
        </div>
    );
}

export default DropdownField;
